import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FaAlignRight, FaArrowRightLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaExclamation } from "react-icons/fa";
import routesConstants from "../routes/routesConstants";
import { axiosDataAcquisitionFreelancer } from "../services/api";
import { LOGOUT } from "../constants/url";
import Cookies from "../services/cookies";
import store from "../store";
import { logout } from "../modules/Auth/slice/AuthSlice";
import { toast } from "react-toastify";
import { SOMETHING_WANTS_WRONG } from "../constants/errorMessages";
import { SlotStatusThunk } from "./slice/Thunk";

const Header = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.Auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { BodyTopHeaderPath, slot_status } = useSelector(
    (state) => state.Layout
  );

  useEffect(() => {
    dispatch(SlotStatusThunk());
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      const response = await axiosDataAcquisitionFreelancer.get(LOGOUT);
      if (!!response?.data) {
        Cookies?.clear();
        store.dispatch(logout());
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || SOMETHING_WANTS_WRONG);
    }
  };

  const handleSideMenuButton = () => setOpen((prev) => !prev);

  const handleChangePassword = () => navigate("/change-password");

  const handleRedirectProfile = () => navigate(routesConstants.PROFILE);

  console.log(user?.profile_image);
  return (
    <nav
      className={`navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row ${
        BodyTopHeaderPath.includes(location.pathname) ? "remove-shadow" : ""
      }`}
    >
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/">
          <h1 className="mb-0">CV CORNOR</h1>
        </Link>
        <Link className="navbar-brand brand-logo-mini" to="/">
          {/* <h1>CV</h1> */}
        </Link>
        <button
          className="btn btn-link"
          type="button"
          style={{ fontSize: "15px", padding: "0 10px" }}
          onClick={() => {
            handleSideMenuButton();
            document.body.classList.toggle("sidebar-icon-only");
          }}
        >
          {isOpen ? <FaArrowRightLong /> : <FaAlignRight />}
        </button>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <div className="navbar-nav navbar-nav-right">
          {slot_status === false ? (
            <div className="d-flex align-items-center">
              <button className="btn btn-danger change-color-after-some-time">
                <FaExclamation />
              </button>
            </div>
          ) : (
            <></>
          )}

          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile">
              <Dropdown align={"end"}>
                <Dropdown.Toggle
                  className="nav-link align-items-center"
                  variant="link"
                >
                  <div className="nav-profile-text me-3">
                    <p className="mb-0 text-black">
                      Hi,{" "}
                      <span className="fw-bold">
                        {user?.first_name} {user?.last_name}
                      </span>
                    </p>
                  </div>
                  <div className="nav-profile-img">
                    <img
                      src={
                        user?.profile_image ||
                        "https://dmapi.oneshotabroad.com/data/assets/admin/freelancer.png"
                      }
                      alt="user"
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-dropdown">
                  <Dropdown.Item className="border-bottom">
                    <div className="d-flex">
                      <div className="nav-profile-img">
                        <img
                          src={
                            user?.profile_image ||
                            "https://dmapi.oneshotabroad.com/data/assets/admin/freelancer.png"
                          }
                          alt="user"
                        />
                      </div>
                      <div className="nav-user-data ms-3">
                        <h5 className="fw-bold">
                          {user?.first_name} {user?.last_name}
                        </h5>
                        <p>{user?.email || ""}</p>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleRedirectProfile}>
                    My Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleChangePassword}>
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout} className="border-top">
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={() =>
            document
              .querySelector(".sidebar-offcanvas")
              .classList.toggle("active")
          }
        >
          <FaAlignRight />
        </button>
      </div>
    </nav>
  );
};

export default Header;
